import React, {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CachedIcon from "@mui/icons-material/Cached";
import {ClearIcon} from "@mui/x-date-pickers";
import {
    Alert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Snackbar
} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import useGetCaseAttribute from "../customHooks/useGetCaseAttributes";
import "../../styles/styleOverviewMenuFilterContainer.css";
import useGetCaseWorkers from "../customHooks/useGetCaseWorkers";
import Button from "@mui/material/Button";
import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid2";

function OverviewMenuFilterContainer({onFilterChange, onClearFilters, rowsPerPage, onFilterRequestBody, currentPage, requestBody}) {
    /* Const section */
    const navigate = useNavigate();
    const location = useLocation(); // Hook for getting the state location
    const navigateToAddNewCases = async () => {
        try {
            // Send the requestBody to the backend
            await axios.post("/overview/saveRequestBody", requestBody, {
                headers: {
                    withCredentials: true,
                },
            });

            // Navigate to the case details page after saving
            navigate("/caseDetails", { state: { currentPage, rowsPerPage } });
        } catch (error) {
            console.error("Error saving requestBody to the backend:", error);
            // Handle error (optional)
        }
    };

    // Check if any filter value is present in the location state
    const isFilterAvailable = location.state?.filter?.some((filter) => filter.value) || false;

    // Initialize state for filter container visibility
    const [isFilterContainerVisible, setIsFilterContainerVisible] = useState(false);

    const [filterCounter, setFilterCounter] = useState(() => {
        let c = 0;
        location.state?.filter?.forEach(f => {
            if (Array.isArray(f.value)) {
                // If f.value is an Array, check if its length is greater than 0
                if (f.value.length > 0){
                    c += 1;
                }
            } else if (f.value){
                // If f.value is not an array, check if it's valid
                c += 1;
            }
        });
        return c;
    });
    const [filterFromDate, setFilterFromDate] = useState(() => {
        const filter = location.state?.filter?.find(f => f.filterOption === "FROM_DATE");
        return filter ? filter.value || null : null;
    });

    const [filterToDate, setFilterToDate] = useState(() => {
        const filter = location.state?.filter?.find(f => f.filterOption === "TO_DATE");
        return filter ? filter.value || null : null;
    });
    const {filterStatusOptions, filterCategoryOptions, filterLabelOptions} = useGetCaseAttribute();
    const {filterUserOptions} = useGetCaseWorkers();
    const [filterStatusSelected, setFilterStatusSelected] = useState(() => {
        const filter = location.state?.filter?.find(f => f.filterOption === "STATUS");
        return filter ? filter.value || [] : [];
    });

    const [filterSelectedCategory, setFilterSelectedCategory] = useState(() => {
        const filter = location.state?.filter?.find(f => f.filterOption === "CATEGORY");
        return filter ? filter.value || [] : [];
    });

    const [filterUserSelected, setFilterUserSelected] = useState(() => {
        const filter = location.state?.filter?.find(f => f.filterOption === "ASSIGNED_USER");
        return filter ? filter.value || [] : [];
    });

    const [filterLabelSelected, setFilterLabelSelected] = useState(() => {
        const filter = location.state?.filter?.find(f => f.filterOption === "LABEL");
        return filter ? filter.value || [] : [];
    });

    const [filterByCaseId, setFilterByCaseId] = useState(() => {
        const filter = location.state?.filter?.find(f => f.filterOption === "CASE");
        return filter ? filter.value || null : null;
    })
    const [filterByName, setFilterByName] = useState(() => {
        const filter = location.state?.filter?.find(f => f.filterOption === "NAME");
        return filter ? filter.value || null : null;
    })
    const [filterByZip, setFilterByZip] = useState(() => {
        const filter = location.state?.filter?.find(f => f.filterOption === "ZIP");
        return filter ? filter.value || null : null;
    })
    const [filterByCity, setFilterByCity] = useState(() => {
        const filter = location.state?.filter?.find(f => f.filterOption === "CITY");
        return filter ? filter.value || null : null;
    })

    const [alertOpen, setAlertOpen] = useState(false);
    const [sortOption, setSortOption] = useState("");

    /* Function section */

    const toggleFilterContainer = () => {
        setIsFilterContainerVisible(!isFilterContainerVisible);
    };

    const handleFromDateChange = (date) => {
        setFilterFromDate(date);
    };

    const handleToDateChange = (date) => {
        setFilterToDate(date);
    };

    const handleFilterStatusChange = (event) => {
        setFilterStatusSelected(event.target.value);
    };

    const handleFilterCategoryChange = (event) => {
        setFilterSelectedCategory(event.target.value);
    };

    const handleFilterLabelChange = (event) => {
        setFilterLabelSelected(event.target.value);
    };

    const handleFilterUserChange = (event) => {
        setFilterUserSelected(event.target.value);
    };

    const handleFilterByCaseIdChange = (event) => {
        setFilterByCaseId(event.target.value);
    };
    const handleFilterByNameChange = (event) => {
        setFilterByName(event.target.value);
    };
    const handleFilterByZipChange = (event) => {
        setFilterByZip(event.target.value);
    };
    const handleFilterByCityChange = (event) => {
        setFilterByCity(event.target.value);
    };


    const parseToDate = (date) => {
        if (date) {
            //let parsedDate = new Date();
            let parsedDate = dayjs(date, ["YYYY-MM-DD", "DD.MM.YYYY"]) || new Date()//dayjs(date.toDate()).format("YYYY-MM-DD");
            //console.log("ParsedDate = ", parsedDate);
            return parsedDate.format("YYYY-MM-DD");
        }
        return null;
    };

    // Get the count of filters and apply filter
    const handleFilterAndRefresh = async () => {
        if (filterFromDate || filterToDate || filterStatusSelected || filterSelectedCategory || filterLabelSelected || filterUserSelected || sortOption || filterByCaseId || filterByName || filterByZip || filterByCity) {
            setFilterCounter(getAppliedFiltersCount());

            // Prepare the request body
            const requestBody = {
                filter: [
                    ...(filterFromDate ? [{
                        filterOption: "FROM_DATE",
                        value: parseToDate(filterFromDate)
                    }] : []),
                    ...(filterToDate ? [{
                        filterOption: "TO_DATE",
                        value: parseToDate(filterToDate)
                    }] : []),
                    ...(filterStatusSelected && filterStatusSelected.length > 0 ? [{
                        filterOption: "STATUS",
                        value: filterStatusSelected
                    }] : []),
                    ...(filterSelectedCategory && filterSelectedCategory.length > 0 ? [{
                        filterOption: "CATEGORY",
                        value: filterSelectedCategory
                    }] : []),
                    ...(filterLabelSelected && filterLabelSelected.length > 0 ? [{
                        filterOption: "LABEL",
                        value: filterLabelSelected
                    }] : []),
                    ...(filterUserSelected && filterUserSelected.length > 0 ? [{
                        filterOption: "ASSIGNED_USER",
                        value: filterUserSelected
                    }] : []),
                    ...(filterByCaseId ? [{
                        filterOption: "CASE",
                        value: filterByCaseId
                    }] : []),
                    ...(filterByName ? [{
                        filterOption: "NAME",
                        value: filterByName
                    }] : []),
                    ...(filterByZip ? [{
                        filterOption: "ZIP",
                        value: filterByZip
                    }] : []),
                    ...(filterByCity ? [{
                        filterOption: "CITY",
                        value: filterByCity
                    }] : []),
                ],
                sortOption: sortOption || null,
                page: 0,
                pageSize: rowsPerPage,
            };

            try {
                // Perform axios request with credentials in headers
                const response = await axios.post("/overview", requestBody, {
                    headers: {
                        withCredentials: true,
                    },
                });
                if (!response.status) {
                    throw new Error("Failed to fetch data");
                }
                console.log("Filter Counter = ", filterCounter);
                const data = await response.data.content;
                onFilterChange(data, response.data.totalElements); // Call the parent component's filter change handler
                setAlertOpen(true); // Show success alert
                onFilterRequestBody(requestBody); // Send requestBody to the parent component
            } catch (error) {
                console.error("Error fetching table rows:", error);
            }
        }
    };

    // Get the count of filters and apply filter
    const handleEingangsDatumSort = async () => {

        // Prepare the request body
        const requestBody = {
            filter: [
                ...(filterFromDate ? [{
                    filterOption: "FROM_DATE",
                    value: parseToDate(filterFromDate)
                }] : []),
                ...(filterToDate ? [{
                    filterOption: "TO_DATE",
                    value: parseToDate(filterToDate)
                }] : []),
                ...(filterStatusSelected && filterStatusSelected.length > 0 ? [{
                    filterOption: "STATUS",
                    value: filterStatusSelected
                }] : []),
                ...(filterSelectedCategory && filterSelectedCategory.length > 0 ? [{
                    filterOption: "CATEGORY",
                    value: filterSelectedCategory
                }] : []),
                ...(filterLabelSelected && filterLabelSelected.length > 0 ? [{
                    filterOption: "LABEL",
                    value: filterLabelSelected
                }] : []),
                ...(filterUserSelected && filterUserSelected.length > 0 ? [{
                    filterOption: "ASSIGNED_USER",
                    value: filterUserSelected
                }] : []),
                ...(filterByCaseId ? [{
                    filterOption: "CASE",
                    value: filterByCaseId
                }] : []),
                ...(filterByName ? [{
                    filterOption: "NAME",
                    value: filterByName
                }] : []),
                ...(filterByZip ? [{
                    filterOption: "ZIP",
                    value: filterByZip
                }] : []),
                ...(filterByCity ? [{
                    filterOption: "CITY",
                    value: filterByCity
                }] : []),
            ],
            sortOption: "ASC" || null,
            page: 0,
            pageSize: rowsPerPage,
        };

        try {
            // Perform axios request with credentials in headers
            const response = await axios.post("/overview", requestBody, {
                headers: {
                    withCredentials: true,
                },
            });
            if (!response.status) {
                throw new Error("Failed to fetch data");
            }
            console.log("Filter Counter = ", filterCounter);
            const data = await response.data.content;
            onFilterChange(data, response.data.totalElements); // Call the parent component's filter change handler
            setAlertOpen(true); // Show success alert
            onFilterRequestBody(requestBody); // Send requestBody to the parent component
        } catch (error) {
            console.error("Error fetching table rows:", error);
        }
    };

    const getAppliedFiltersCount = () => {
        let count = 0;
        if (filterFromDate) count++;
        if (filterToDate) count++;
        if (filterStatusSelected.length !==0) count++;
        if (filterSelectedCategory.length !==0) count++;
        if (filterLabelSelected.length !==0) count++;
        if (filterUserSelected.length !==0) count++;
        if (filterByCaseId) count++;
        if (filterByName) count++;
        if (filterByCity) count++;
        if (filterByZip) count++;
        if (sortOption) count++;
        return count;
    };

    const handleFilterToday = async () => {
        const today = new Date().toISOString().split("T")[0];

        const requestBody = {
            filter: [
                ...(today ? [{
                    filterOption: "FROM_DATE",
                    value: today
                }] : []),
                ...(today ? [{
                    filterOption: "TO_DATE",
                    value: today
                }] : []),
                ...(filterStatusSelected && filterStatusSelected.length > 0 ? [{
                    filterOption: "STATUS",
                    value: filterStatusSelected
                }] : []),
                ...(filterSelectedCategory && filterSelectedCategory.length > 0 ? [{
                    filterOption: "CATEGORY",
                    value: filterSelectedCategory
                }] : []),
                ...(filterLabelSelected && filterLabelSelected.length > 0 ? [{
                    filterOption: "LABEL",
                    value: filterLabelSelected
                }] : []),
                ...(filterUserSelected && filterUserSelected.length > 0 ? [{
                    filterOption: "ASSIGNED_USER",
                    value: filterUserSelected
                }] : []),
                ...(filterByCaseId ? [{
                    filterOption: "CASE",
                    value: filterByCaseId
                }] : []),
                ...(filterByName ? [{
                    filterOption: "NAME",
                    value: filterByName
                }] : []),
                ...(filterByZip ? [{
                    filterOption: "ZIP",
                    value: filterByZip
                }] : []),
                ...(filterByCity ? [{
                    filterOption: "CITY",
                    value: filterByCity
                }] : []),
            ],
            sortOption: sortOption || null,
            page: 0,
            pageSize: rowsPerPage,
        };

        try {
            const response = await axios.post(
                "/overview",
                requestBody,
                {
                    headers: {
                        withCredentials: true,
                    },
                }
            );

            if (response.status !== 200) {
                throw new Error("Failed to fetch data");
            }

            const data = response.data.content;
            onFilterChange(data, response.data.totalElements); // Call the parent component's filter change handler
            setAlertOpen(true); // Show success alert
            onFilterRequestBody(requestBody); // Send requestBody to the parent component
        } catch (error) {
            console.error("Error fetching table rows:", error);
        }
    };

    const handleFilterYesterday = async () => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const yesterdayStr = yesterday.toISOString().split("T")[0];

        const requestBody = {
            filter: [
                ...(yesterdayStr ? [{
                    filterOption: "FROM_DATE",
                    value: yesterdayStr
                }] : []),
                ...(yesterdayStr ? [{
                    filterOption: "TO_DATE",
                    value: yesterdayStr
                }] : []),
                ...(filterStatusSelected && filterStatusSelected.length > 0 ? [{
                    filterOption: "STATUS",
                    value: filterStatusSelected
                }] : []),
                ...(filterSelectedCategory && filterSelectedCategory.length > 0 ? [{
                    filterOption: "CATEGORY",
                    value: filterSelectedCategory
                }] : []),
                ...(filterLabelSelected && filterLabelSelected.length > 0 ? [{
                    filterOption: "LABEL",
                    value: filterLabelSelected
                }] : []),
                ...(filterUserSelected && filterUserSelected.length > 0 ? [{
                    filterOption: "ASSIGNED_USER",
                    value: filterUserSelected
                }] : []),
                ...(filterByCaseId ? [{
                    filterOption: "CASE",
                    value: filterByCaseId
                }] : []),
                ...(filterByName ? [{
                    filterOption: "NAME",
                    value: filterByName
                }] : []),
                ...(filterByZip ? [{
                    filterOption: "ZIP",
                    value: filterByZip
                }] : []),
                ...(filterByCity ? [{
                    filterOption: "CITY",
                    value: filterByCity
                }] : []),
            ],
            sortOption: sortOption || null,
            page: 0,
            pageSize: rowsPerPage,
        };

        try {
            const response = await axios.post(
                "/overview",
                requestBody,
                {
                    headers: {
                        withCredentials: true,
                    },
                }
            );

            if (response.status !== 200) {
                throw new Error("Failed to fetch data");
            }

            const data = response.data.content;
            onFilterChange(data, response.data.totalElements); // Call the parent component's filter change handler
            setAlertOpen(true); // Show success alert
            onFilterRequestBody(requestBody); // Send requestBody to the parent component
        } catch (error) {
            console.error("Error fetching table rows:", error);
        }
    };

    const handleFilterLastThreeDays = async () => {
        const today = new Date();
        const threeDaysAgo = new Date();
        threeDaysAgo.setDate(today.getDate() - 3);
        const threeDaysAgoStr = threeDaysAgo.toISOString().split("T")[0];

        const requestBody = {
            filter: [
                ...(threeDaysAgoStr ? [{
                    filterOption: "FROM_DATE",
                    value: threeDaysAgoStr
                }] : []),
                ...(threeDaysAgoStr ? [{
                    filterOption: "TO_DATE",
                    value: today.toISOString().split("T")[0]
                }] : []),
                ...(filterStatusSelected && filterStatusSelected.length > 0 ? [{
                    filterOption: "STATUS",
                    value: filterStatusSelected
                }] : []),
                ...(filterSelectedCategory && filterSelectedCategory.length > 0 ? [{
                    filterOption: "CATEGORY",
                    value: filterSelectedCategory
                }] : []),
                ...(filterLabelSelected && filterLabelSelected.length > 0 ? [{
                    filterOption: "LABEL",
                    value: filterLabelSelected
                }] : []),
                ...(filterUserSelected && filterUserSelected.length > 0 ? [{
                    filterOption: "ASSIGNED_USER",
                    value: filterUserSelected
                }] : []),
                ...(filterByCaseId ? [{
                    filterOption: "CASE",
                    value: filterByCaseId
                }] : []),
                ...(filterByName ? [{
                    filterOption: "NAME",
                    value: filterByName
                }] : []),
                ...(filterByZip ? [{
                    filterOption: "ZIP",
                    value: filterByZip
                }] : []),
                ...(filterByCity ? [{
                    filterOption: "CITY",
                    value: filterByCity
                }] : []),
            ],
            sortOption: sortOption || null,
            page: 0,
            pageSize: rowsPerPage,
        };

        try {
            const response = await axios.post(
                "/overview",
                requestBody,
                {
                    headers: {
                        withCredentials: true,
                    },
                }
            );

            if (response.status !== 200) {
                throw new Error("Failed to fetch data");
            }

            const data = response.data.content;
            onFilterChange(data, response.data.totalElements); // Call the parent component's filter change handler
            setAlertOpen(true); // Show success alert
            onFilterRequestBody(requestBody); // Send requestBody to the parent component
        } catch (error) {
            console.error("Error fetching table rows:", error);
        }
    };

    // Handle quick filter - last thirty days
    const handleFilterLastThirtyDays = async () => {
        const today = new Date();
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(today.getDate() - 30);
        const thirtyDaysAgoStr = thirtyDaysAgo.toISOString().split("T")[0];

        const requestBody = {
            filter: [
                ...(thirtyDaysAgoStr ? [{
                    filterOption: "FROM_DATE",
                    value: thirtyDaysAgoStr
                }] : []),
                ...(thirtyDaysAgoStr ? [{
                    filterOption: "TO_DATE",
                    value: today.toISOString().split("T")[0]
                }] : []),
                ...(filterStatusSelected && filterStatusSelected.length > 0 ? [{
                    filterOption: "STATUS",
                    value: filterStatusSelected
                }] : []),
                ...(filterSelectedCategory && filterSelectedCategory.length > 0 ? [{
                    filterOption: "CATEGORY",
                    value: filterSelectedCategory
                }] : []),
                ...(filterLabelSelected && filterLabelSelected.length > 0 ? [{
                    filterOption: "LABEL",
                    value: filterLabelSelected
                }] : []),
                ...(filterUserSelected && filterUserSelected.length > 0 ? [{
                    filterOption: "ASSIGNED_USER",
                    value: filterUserSelected
                }] : []),
                ...(filterByCaseId ? [{
                    filterOption: "CASE",
                    value: filterByCaseId
                }] : []),
                ...(filterByName ? [{
                    filterOption: "NAME",
                    value: filterByName
                }] : []),
                ...(filterByZip ? [{
                    filterOption: "ZIP",
                    value: filterByZip
                }] : []),
                ...(filterByCity ? [{
                    filterOption: "CITY",
                    value: filterByCity
                }] : []),
            ],
            sortOption: sortOption || null,
            page: 0,
            pageSize: rowsPerPage,
        };

        try {
            const response = await axios.post(
                "/overview",
                requestBody,
                {
                    headers: {
                        withCredentials: true,
                    },
                }
            );

            if (response.status !== 200) {
                throw new Error("Failed to fetch data");
            }

            const data = response.data.content;
            onFilterChange(data, response.data.totalElements); // Call the parent component's filter change handler
            setAlertOpen(true); // Show success alert
            onFilterRequestBody(requestBody); // Send requestBody to the parent component
        } catch (error) {
            console.error("Error fetching table rows:", error);
        }
    };

    // Function to handle clearing all filters...
    const handleClearFilters = () => {
        setFilterFromDate(null);
        setFilterToDate(null);
        setFilterStatusSelected([]);
        setFilterSelectedCategory([]);
        setFilterLabelSelected([]);
        setFilterUserSelected([]);
        setFilterByCity("");
        setFilterByCaseId("");
        setFilterByZip("")
        setFilterByName("")
        setFilterCounter(0);
        setSortOption("");
        onClearFilters(); // Notify parent to reset allCases
    };

    return (
        <div className="divOverviewMenuFilterContainer">
            <div className="divOverviewButtonContainer">
                <button onClick={navigateToAddNewCases} className="addIconButton">
                    <AddIcon/>
                </button>
                <button hidden={true}>
                    <SearchIcon/>
                </button>
                <button onClick={toggleFilterContainer} className="filterIconButton">
                    <div className="filterButton">
                        <FilterAltOutlinedIcon/>
                        {filterCounter > 0 && <span className="filterCounter">{filterCounter}</span>}
                    </div>
                </button>
                <button onClick={handleFilterAndRefresh} className="cachedIconButton">
                    <CachedIcon/>
                </button>
                <button className="textButtons" onClick={handleFilterToday}>
                    Heute
                </button>
                <button className="textButtons" onClick={handleFilterYesterday}>
                    Gestern
                </button>
                <button className="textButtons" onClick={handleFilterLastThreeDays}>
                    Letzten 3 Tage
                </button>
                <button className="textButtons" onClick={handleFilterLastThirtyDays}>
                    Letzten 30 Tage
                </button>
                <button className="textButtons" onClick={handleEingangsDatumSort}>
                    Sortieren Eingangsdatum aufsteigend
                </button>
                <IconButton className="textButtons" onClick={handleClearFilters}>
                    Filter löschen
                    <ClearIcon className="textButtonsIcon"/>
                </IconButton>
            </div>

            <Dialog open={isFilterContainerVisible} onClose={() => {
                setIsFilterContainerVisible(false)
            }} maxWidth="xs">
                <DialogTitle align={"center"} fontWeight={"bold"}>Fälle einschränken</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Grid container spacing={2}>
                            <Grid size={12}>
                                <TextField value={filterByCaseId} onChange={handleFilterByCaseIdChange} margin="dense"
                                           id="name" name="caselike" label="Fall ID" type="number" fullWidth
                                           variant="standard" sx={{
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                        display: "none",
                                    }, "& input[type=number]": {
                                        MozAppearance: "textfield",
                                    },
                                }}
                                />
                            </Grid>
                            <Grid size={12}>
                                <TextField
                                    value={filterByName} onChange={handleFilterByNameChange} margin="dense" id="name"
                                    name="namelike" label="Name" type="name" fullWidth variant="standard"
                                /></Grid>
                            <Grid size={2}>
                                <TextField value={filterByZip} onChange={handleFilterByZipChange} margin="dense"
                                           id="name" name="ziplike" label="PLZ" type="number" variant="standard"
                                           sx={{
                                               "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                                   display: "none",
                                               },
                                               "& input[type=number]": {
                                                   MozAppearance: "textfield",
                                               },
                                           }}
                                />
                            </Grid>
                            <Grid size={10}>
                                <TextField
                                    value={filterByCity} onChange={handleFilterByCityChange} margin="dense" id="name"
                                    name="citylike" label="Ort" type="name" fullWidth variant="standard"
                                />
                            </Grid>
                            <Grid size={12}>&nbsp;</Grid>
                            <Grid size={6}>
                                <FormControl className="fcFormControl">
                                    <InputLabel>Status</InputLabel>
                                    <Select multiple value={filterStatusSelected} onChange={handleFilterStatusChange} renderValue={(selected) => selected.map((status) => filterStatusOptions.find((option) => option.id === status)?.text || status).join(", ")}>
                                        <MenuItem id="fcSelectBoxItems" value="Status auswählen" disabled>
                                            <em>Status auswählen</em>{" "}
                                        </MenuItem>
                                        {filterStatusOptions.map((filterStatusOption, idx) => (
                                            <MenuItem id="fcSelectBoxItems" value={filterStatusOption.id} key={idx}>
                                                {filterStatusOption.text}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid size={6}>
                                <FormControl className="fcFormControl">
                                    <InputLabel>Kategorien</InputLabel>
                                    <Select multiple value={filterSelectedCategory} onChange={handleFilterCategoryChange} renderValue={(selected) => selected.map((category) => filterCategoryOptions.find((option) => option.id === category)?.text || category).join(", ")}>
                                        <MenuItem id="fcSelectBoxItems" value="Kategorie auswählen" disabled>
                                            <em>Kategorie auswählen</em>{" "}
                                        </MenuItem>
                                        {filterCategoryOptions.map((filterCategoryOption, idx) => (
                                            <MenuItem id="fcSelectBoxItems" value={filterCategoryOption.id} key={idx}>
                                                {filterCategoryOption.text}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid size={6}>
                                <FormControl className="fcFormControl">
                                    <InputLabel>Benutzer</InputLabel>
                                    <Select multiple value={filterUserSelected} onChange={handleFilterUserChange} renderValue={(selected) => selected.map((user) => {
                                        const matchedUser = filterUserOptions.find((option) => option.id === user);
                                        return matchedUser ? `${matchedUser.firstName} ${matchedUser.lastName}` : user;
                                    }).join(", ")}>
                                        <MenuItem id="fcSelectBoxItems" value="Bearbeiter auswählen" disabled>
                                            <em>Bearbeiter auswählen</em>{" "}
                                        </MenuItem>
                                        {filterUserOptions.map((filterUserOption, idx) => (
                                            <MenuItem id="fcSelectBoxItems" value={filterUserOption.id} key={idx}>
                                                {filterUserOption.firstName} {filterUserOption.lastName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid size={6}>
                                <FormControl className="fcFormControl">
                                    <InputLabel>Labels</InputLabel>
                                    <Select multiple value={filterLabelSelected} onChange={handleFilterLabelChange} renderValue={(selected) => selected.map((label) => filterLabelOptions.find((option) => option.id === label)?.text || label).join(", ")}>
                                        <MenuItem id="fcSelectBoxItems" value="">
                                            <em>Bitte Label auswählen</em>
                                        </MenuItem>
                                        {filterLabelOptions.map((filterLabelOption) => (
                                            <MenuItem id="fcSelectBoxItems" key={filterLabelOption.id} value={filterLabelOption.id}>
                                                {filterLabelOption.text}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Grid size={6}> <DatePicker className="fcDatePickers" label="Eingangsdatum von"
                                                            value={filterFromDate ? dayjs(filterFromDate, ["DD.MM.YYYY", "YYYY-MM-DD"]) : null}
                                                            onChange={handleFromDateChange} format="DD.MM.YYYY"/></Grid>
                                <Grid size={6}><DatePicker className="fcDatePickers" variant="standard"
                                                           label="Eingangsdatum bis"
                                                           value={filterToDate ? dayjs(filterToDate, ["DD.MM.YYYY", "YYYY-MM-DD"]) : null}
                                                           onChange={handleToDateChange} format="DD.MM.YYYY"/></Grid>
                            </LocalizationProvider>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setIsFilterContainerVisible(false)
                    }} className="cmdCloseFilter" size="small"
                            startIcon={<CloseIcon className="cmdCloseFilterIcon"/>}>Schließen</Button>
                    <Button className="cmdSetFilter" size="small"
                            startIcon={<ChecklistOutlinedIcon className="cmdSetFilterIcon"/>}
                            onClick={handleFilterAndRefresh} // Open file dialog
                    >Filter anwenden</Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={() => setAlertOpen(false)}>
                <Alert onClose={() => setAlertOpen(false)} severity="success">
                    Filter erfolgreich angewendet!
                </Alert>
            </Snackbar>
        </div>
    );
}

export default OverviewMenuFilterContainer;
