import { useState, useEffect } from "react";
import axios from "axios";

const useGetCaseWorkers = () => {
  const [actionAssigneeOptions, setActionAssigneeOptions] = useState([]);
  const [filterUserOptions, setFilterUserOptions] = useState([]);
  const [caseDetailsAssigneeOptions, setCaseDetailsAssigneeOptions] = useState([]);

  const getCaseWorkers = async () => {
    try {
      const response = await axios.get("/list/users", {
        headers: {
          withCredentials: true,
        },
      });
      const allUser = response.data;
      const activeUser = allUser.filter(user=>user.activeUser);
      setActionAssigneeOptions(activeUser);
      setFilterUserOptions(allUser);
      setCaseDetailsAssigneeOptions(activeUser);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  useEffect(() => {
    getCaseWorkers();
  }, []);

  return { actionAssigneeOptions, filterUserOptions, caseDetailsAssigneeOptions };
};

export default useGetCaseWorkers;
