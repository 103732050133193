import React, { useMemo, useState } from "react";
import axios from "axios";
import { Accordion, AccordionDetails, AccordionSummary, Divider } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CaseDetailMenuContainer from "./CaseDetailMenuContainer";
import CaseDetailPersonaContainer from "./CaseDetailPersonaContainer";
import CaseDetailDocumentContainer from "./CaseDetailDocumentContainer";
import CaseDetailHitSelectionContainer from "./CaseDetailHitSelectionContainer";
import CaseDetailCommentContainer from "./CaseDetailCommentContainer";
import CaseDetailHitSelectionTableContainer from "./CaseDetailHitSelectionTableContainer";
import { useLocation } from "react-router-dom";
import "../../styles/styleCaseDetails.css";
import useGetCaseDetails from "../customHooks/useGetCaseDetails";

function CaseDetails() {
  const initialValues = useMemo(
      () => ({
        personal: {
          gender: "",
          firstName: "",
          lastName: "",
          birthDate: "",
          birthName: "",
          emailAddress: "",
        },
        location: {
          street: "",
          houseNo: "",
          zip: "",
          city: "",
          country: "",
          locationType: "",
        },
        statusCode: "",
        categoryCode: "",
        labels: [],
        archivId: "",
        searchResults: [],
        eingangsDatum: "",
      }),
      []
  );

  const location = useLocation();
  const { rowData, currentPage, rowsPerPage } = location.state || {};

  const {
    personData,
    setPersonData,
    dspSearchResults,
    tipSearchResults,
    cspSearchResults,
    selectedStatus,
    setSelectedStatus,
    eingangsDatum,
    setEingangsDatum,
    dueDate,
    setDueDate,
    selectedCategory,
    setSelectedCategory,
    selectedLabels,
    setSelectedLabels,
    selectedAssignee,
    setSelectedAssignee,
    comments,
    setComments,
    dspLastMatchedDate,
    tipLastMatchedDate,
    cspLastMatchedDate,
    dspReportCreationDate,
    tipReportCreationDate,
    cspReportCreationDate,
    dspSelectedIdentifier,
    tipSelectedIdentifier,
    cspSelectedIdentifier,
  } = useGetCaseDetails(initialValues, rowData);

  const [selectedRows, setSelectedRows] = useState({ DSP: [], TIP: [], CSP: [] });
  const [selectedItems, setSelectedItems] = useState({ tip: true, dsp: false, csp: true, unscharfeSuchenDsp: true });
  const [reset, setReset] = useState(false);
  const [triggerDocumentsSection, setTriggerDocumentsSection] = useState(false);

  const [selectedDspRow, setSelectedDspRow] = useState([]);
  const [selectedTipRow, setSelectedTipRow] = useState([]);
  const [selectedCspRow, setSelectedCspRow] = useState([]);

  // Function for changing state
  const handleTriggerDocumentsSection = () => {
    setTriggerDocumentsSection((prev) => !prev);
  }

  const handleSelectedRowsChange = (selectedRows) => {
    setSelectedRows(selectedRows);
  };

  const handleSelectionChange = (item, isChecked) => {
    if (item === "dsp" && isChecked) {
      // If DSP is checked, uncheck Unscharfesuchen
      setSelectedItems((prevSelectedItems) => ({
        ...prevSelectedItems,
        dsp: true,
        unscharfeSuchenDsp: false,
      }));
    } else if (item === "unscharfeSuchenDsp" && isChecked) {
      // If Unscharfesuchen is checked, uncheck DSP
      setSelectedItems((prevSelectedItems) => ({
        ...prevSelectedItems,
        unscharfeSuchenDsp: true,
        dsp: false,
      }));
    } else {
      setSelectedItems((prevSelectedItems) => ({
        ...prevSelectedItems,
        [item]: isChecked,
      }));
    }
  };

  const handleRefresh = async (selectedItems) => {
    setReset(true); // Trigger the reset

    const refreshBody = [];
    if (selectedItems.dsp) refreshBody.push("DSP");
    if (selectedItems.tip) refreshBody.push("TIP");
    if (selectedItems.csp) refreshBody.push("CSP");
    if (selectedItems.unscharfeSuchenDsp) refreshBody.push("UNSCHARFESUCHEN");

    try {
      await axios.post(`/refresh/case/${rowData.id}`, refreshBody, {
        headers: { withCredentials: true },
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    // Refresh the page
    window.location.reload();

    setReset(false); // Reset state after one render cycle
  };

  const handleRowSelectAPI = async (selectedRow, tableType) => {
    if (selectedRow.length > 0) {
      // Prepare request body
      const requestBody = selectedRow.map((row) => ({
        platform: tableType,
        identifier: row.id || "",
      }));

      try {
        const response = await axios.put(`/case/selection/${rowData.id}`, requestBody, {
          headers: { withCredentials: true },
        });

        // Extract DSP and TIP results based on platformCode
        const dspResults = response.data.filter((result) => result.platformCode === 901);
        const tipResults = response.data.filter((result) => result.platformCode === 902);
        const cspResults = response.data.filter((result) => result.platformCode === 903);

        // Function to extract candidate data
        const extractCandidateData = (candidate) => {
          const address = candidate.addresses[0];
          return {
            id: candidate.id || "",
            firstName: address?.person?.firstname || "",
            lastName: address?.person?.name || "",
            birthDate: address?.person?.birthDate ? address.person.birthDate.join("-") : "",
            street: address?.location?.street || "",
            houseNo: address?.location?.houseNumber || "",
            zipCode: address?.location?.zipCode || "",
            city: address?.location?.city || "",
            country: address?.location?.country || "",
          };
        };

        // Find matched candidates from results
        const findMatchedCandidates = (results) => {
          return selectedRow
              .map((row) => {
                const matchedCandidate = results.flatMap((result) => result.results.candidates).find((candidate) => candidate.id === row.id);
                return matchedCandidate ? extractCandidateData(matchedCandidate) : null;
              })
              .filter((candidate) => candidate !== null);
        };

        // Get matched DSP and TIP candidates
        const matchedDspCandidates = findMatchedCandidates(dspResults);
        const matchedTipCandidates = findMatchedCandidates(tipResults);
        const matchedCspCandidates = findMatchedCandidates(cspResults);

        // Set the state with matched candidates
        setSelectedDspRow(matchedDspCandidates);
        setSelectedTipRow(matchedTipCandidates);
        setSelectedCspRow(matchedCspCandidates);
      } catch (error) {
        console.error("Error sending row data:", error);
      }
    } else {
      setSelectedDspRow([]);
      setSelectedTipRow([]);
      setSelectedCspRow([]);
      // Prepare request body
      const requestBody =
          selectedRow.length > 0
              ? selectedRow.map((row) => ({
                platform: tableType,
                identifier: row.id || "",
              }))
              : [
                {
                  platform: tableType,
                  identifier: "",
                },
              ];

      await axios.put(`/case/selection/${rowData.id}`, requestBody, {
        headers: { withCredentials: true },
      });
    }
  };

  return (
      <div className="divCDRootContainer">
        <div className="divCDTopContent">
          <CaseDetailMenuContainer
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              eingangsDatum={eingangsDatum}
              setEingangsDatum={setEingangsDatum}
              dueDate={dueDate}
              setDueDate={setDueDate}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              selectedLabels={selectedLabels}
              setSelectedLabels={setSelectedLabels}
              selectedAssignee={selectedAssignee}
              setSelectedAssignee={setSelectedAssignee}
              selectedRows={selectedRows}
              rowData={rowData}
              rowsPerPage={rowsPerPage}
              currentPage={currentPage}
              dspLastMatchedDate={dspLastMatchedDate}
              tipLastMatchedDate={tipLastMatchedDate}
              cspLastMatchedDate={cspLastMatchedDate}
              dspReportCreationDate={dspReportCreationDate}
              tipReportCreationDate={tipReportCreationDate}
              cspReportCreationDate={cspReportCreationDate}
              onTriggerNotify={handleTriggerDocumentsSection}
          />
        </div>
        <Divider className="divider" id="divider" />
        <div className="divCDMainContent">
          <div className="divCDMainLeftContent">
            <CaseDetailDocumentContainer rowData={rowData} triggerDocumentsSection={triggerDocumentsSection} />
          </div>
          <div className="divCDMainCenterContent">
            <Accordion className="divCDMainAccordion" defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                <div className="hlRequestData">Anfragedaten</div>
              </AccordionSummary>
              <AccordionDetails>
                <CaseDetailPersonaContainer
                    personData={personData}
                    setPersonData={setPersonData}
                    rowData={rowData}
                    selectedStatus={selectedStatus}
                    setSelectedStatus={setSelectedStatus}
                    eingangsDatum={eingangsDatum}
                    setEingangsDatum={setEingangsDatum}
                    dueDate={dueDate}
                    setDueDate={setDueDate}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                    selectedLabels={selectedLabels}
                    setSelectedLabels={setSelectedLabels}
                    selectedAssignee={selectedAssignee}
                    setSelectedAssignee={setSelectedAssignee}
                    comments={comments}
                    setComments={setComments}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion className="divCDMainAccordion" defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
                <div className="hlHitResult">Trefferliste pro Produkt</div>
              </AccordionSummary>
              <AccordionDetails>
                <CaseDetailHitSelectionTableContainer
                    rowData={rowData}
                    dspSearchResults={selectedDspRow.length > 0 ? selectedDspRow : dspSearchResults}
                    tipSearchResults={selectedTipRow.length > 0 ? selectedTipRow : tipSearchResults}
                    cspSearchResults={selectedCspRow.length > 0 ? selectedCspRow : cspSearchResults}
                    onSelectedRowsChange={handleSelectedRowsChange}
                    reset={reset}
                    onRowSelect={handleRowSelectAPI}
                    selectedDspRow={selectedDspRow}
                    selectedTipRow={selectedTipRow}
                    selectedCspRow={selectedCspRow}
                    dspLastMatchedDate={dspLastMatchedDate}
                    tipLastMatchedDate={tipLastMatchedDate}
                    cspLastMatchedDate={cspLastMatchedDate}
                    dspReportCreationDate={dspReportCreationDate}
                    tipReportCreationDate={tipReportCreationDate}
                    cspReportCreationDate={cspReportCreationDate}
                    dspSelectedIdentifier={dspSelectedIdentifier}
                    tipSelectedIdentifier={tipSelectedIdentifier}
                    cspSelectedIdentifier={cspSelectedIdentifier}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion className="divCDMainAccordion" defaultExpanded={true}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header">
                <div className="hlCommentary">Kommentare</div>
              </AccordionSummary>
              <AccordionDetails>
                <CaseDetailCommentContainer rowData={rowData} comments={comments} setComments={setComments} />
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="divCDMainRightContent">
            <CaseDetailHitSelectionContainer selectedItems={selectedItems} onSelectionChange={handleSelectionChange} onRefresh={handleRefresh} />
          </div>
        </div>

        <div className="divCDFooterContent" />
      </div>
  );
}

export default CaseDetails;
